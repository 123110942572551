import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";


export default function ImprintPage() {
  const data = useStaticQuery(graphql`
  {
    mdx(frontmatter: {slug: {eq:"imprint"}}) {
      body
    }
  }
  `)

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About"
      />
    <section className="max-w-4xl mx-auto px-4 py-8 md:p-8 text-2xl md:text-3xl">
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </section>
    </Layout>
  );
}
